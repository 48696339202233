import type { PlatformCountryCode } from '@miimosa/common/types'

import { direct } from './direct'
import { proxied } from './proxied'
import type {
  Partnership,
  FunnelExample,
  Partner,
  UseCase,
  ManifestTestimony,
  CarassoNews,
  CarassoProjectCard,
  CarassoCategory,
  CarassoImpact,
  DirectusLendingStatsResponse,
  Testimonial,
  PlatformMetrics,
  SingleResponse,
  Logic,
  Filter,
  VigneronProject,
  InnocentKidsProject,
} from './types'
import { ArrayResponse, Fetcher } from './types'

const fetcher = typeof window === 'undefined' ? direct : proxied

export function partnerships(platform: PlatformCountryCode, limit: number, page?: number) {
  return (fetcher as Fetcher<ArrayResponse<Partnership>>)({
    resource: 'partnerships',
    limit,
    fields: ['name', 'url', 'image.filename_disk', 'hashtag', 'desc'],
    filter: {
      branch: {
        _in: ['any', platform],
      },
    },
    page: (page ?? 0) + 1, // directus page starts at 1....
    meta: 'filter_count',
  })
}

export function partners(platform: PlatformCountryCode) {
  return (fetcher as Fetcher<ArrayResponse<Partner>>)({
    resource: 'partners',
    limit: undefined,
    fields: ['name', 'slug', 'image.filename_disk', 'translations.short_description'],
    filter: {
      branch: {
        _in: ['any', platform],
      },
    },
    meta: 'filter_count',
  })
}

export function partner(params: { slug: string }) {
  return (fetcher as Fetcher<SingleResponse<Partner[]>>)({
    resource: 'partners',
    sort: undefined,
    filter: { slug: params.slug },
    fields: ['slug', 'id', 'image.filename_disk', 'name', 'translations.description', 'translations.short_description'],
  })
}

export function funnelExamples(platform: PlatformCountryCode) {
  return (fetcher as Fetcher<ArrayResponse<FunnelExample>>)({
    resource: 'funnel_exemples',
    fields: ['project_name', 'firstname', 'collect_type', 'image.filename_disk', 'position', 'achievment'],
    filter: {
      branch: {
        _in: ['any', platform],
      },
    },
  })
}

// we must prefix with get as use is reserved for hooks
export function getUseCases(platform: PlatformCountryCode) {
  return (fetcher as Fetcher<ArrayResponse<UseCase>>)({
    resource: 'use_cases',
    fields: ['description', 'photo.filename_disk'],
    filter: {
      branch: {
        _in: ['any', platform],
      },
    },
  })
}

export const manifestTestimonies = (locale: string) => {
  return (fetcher as Fetcher<ArrayResponse<ManifestTestimony>>)({
    resource: 'manifest_v4_testimony',
    fields: [
      'image.filename_disk',
      'username',
      'link_to',
      'translations.title',
      'translations.description',
      'translations.link_name',
    ],
    sort: 'position',
    deep: {
      translations: {
        _filter: {
          languages_code: locale.includes('nl') ? 'nl' : 'fr',
        },
      },
    },
  })
}

export const lendingStats = () => {
  return (fetcher as Fetcher<SingleResponse<DirectusLendingStatsResponse>>)({
    resource: 'lending_stats',
    fields: [
      '*',
      'risk_director_picture.filename_disk',
      'acpr_default_rates.filename_disk',
      'fpf_performance_indicators.filename_disk',
    ],
    sort: undefined,
  })
}

export const testimonials = ({ displayHome }: { displayHome?: boolean }) => {
  return (fetcher as Fetcher<ArrayResponse<Testimonial>>)({
    resource: 'testimonials',
    sort: 'position',
    fields: [
      'project_owner_name',
      'project_title',
      'content',
      'collected_amount',
      'pledges_count',
      'collect_duration',
      'category',
      'project_url',
      'collect_type',
      'image.filename_disk',
      'avatar.filename_disk',
      'impact.impacts_id.title',
    ],
    ...(displayHome && {
      filter: {
        display_home: {
          _eq: true,
        },
      },
    }),
  })
}

export const platformMetrics = () => {
  return (fetcher as Fetcher<SingleResponse<PlatformMetrics>>)({
    resource: 'platform_metric',
    sort: undefined,
    fields: ['projects_count', 'users_count', 'collected_amount'],
  })
}

// CARASSO

export function getCarassoProjectCards({ slug, displayHome }: { slug?: string; displayHome?: boolean }) {
  return (fetcher as Fetcher<ArrayResponse<CarassoProjectCard>>)({
    resource: 'carasso_project_cards',
    sort: undefined,
    fields: [
      'project_title',
      'location',
      'slug',
      'description',
      'name',
      'photo_porteur.filename_disk',
      'photo_projet.filename_disk',
      'dotation_amount',
      'tagline',
      'siren',
      'project_description',
      'jury_conclusion',
      'jury_member',
      'choice_motivation',
      'project_owner_description',
      'funding_objective',
      'impact.carasso_impacts_id.slug',
      'impact.carasso_impacts_id.title',
      'impact.carasso_impacts_id.color',
      'news.title',
      'news.date',
      'news.news_description',
      'miimosa_slug',
    ],
    ...(slug && {
      filter: {
        slug,
      },
    }),
    ...(displayHome && {
      filter: {
        display_home: {
          _eq: true,
        },
      },
    }),
  })
}

export const carassoProjects = (filter?: Logic | Filter, page?: number) => {
  return (fetcher as Fetcher<ArrayResponse<CarassoProjectCard>>)({
    resource: 'carasso_project_cards',
    fields: [
      'photo_projet.filename_disk',
      'location',
      'dotation_amount',
      'impact.carasso_impacts_id.color',
      'impact.carasso_impacts_id.slug',
      'impact.carasso_impacts_id.title',
      'description',
      'photo_porteur.filename_disk',
      'name',
      'project_title',
      'slug',
      'miimosa_slug',
    ],
    sort: undefined,
    page: (page ?? 0) + 1, // directus page starts at 1....
    filter,
    meta: 'filter_count',
  })
}

export const carassoCategories = () => {
  return (fetcher as Fetcher<ArrayResponse<CarassoCategory>>)({
    resource: 'carasso_categories',
    fields: ['slug', 'title'],
    sort: undefined,
  })
}

export const carassoNews = () => {
  return (fetcher as Fetcher<ArrayResponse<CarassoNews>>)({
    resource: 'carasso_project_news',
    fields: ['title', 'date', 'news_description', 'project.slug', 'project.project_title'],
    sort: '-date',
  })
}
export const carassoImpacts = () => {
  return (fetcher as Fetcher<ArrayResponse<CarassoImpact>>)({
    resource: 'carasso_impacts',
    fields: ['slug', 'title', 'color'],
    sort: undefined,
  })
}

export const vigneronsProjects = () => {
  return (fetcher as Fetcher<ArrayResponse<VigneronProject>>)({
    resource: 'nous_vignerons_project_cards',
    fields: ['owner', 'age', 'region', 'slug', 'awarded', 'impact'],
    sort: undefined,
  })
}

export const innocentKidsProjects = () => {
  return (fetcher as Fetcher<ArrayResponse<InnocentKidsProject>>)({
    resource: 'innocent_kids_projects',
    fields: ['title', 'location', 'image.filename_disk', 'description'],
    sort: undefined,
  })
}
