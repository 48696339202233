import axios from 'axios'
import qs from 'qs'

import { Query } from './types'

// These values should not be shared with the outside
const baseURL = process.env.DIRECTUS_ENDPOINT
const directusAPIKey = process.env.DIRECTUS_API_KEY

const client = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${directusAPIKey}`,
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'comma' })
  },
})

export function fetchRaw<T>(res: string, params: unknown) {
  return client.get<T>('/items/' + res, {
    params,
  })
}

// fetch calls directus directly and should only be called from the server side
export function direct<T>({ resource, filter, ...q }: Query) {
  const params: Record<string, unknown> = {
    sort: 'position',
    limit: 20,
    ...q,
  }
  if (filter) {
    params.filter = JSON.stringify(filter)
  }
  return fetchRaw<T>(resource, params)
}
