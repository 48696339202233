import { Container, BoxProps } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import NumberFormat from 'react-number-format'

import { s3ImageURL } from '@miimosa/common'
import { Carousel } from '@miimosa/design-system'
import { Image } from '@miimosa/design-system/components'
import { directusAsset, Testimonial } from '@miimosa/directus'

import { FocusStory } from '@components'

interface Props {
  testimonials: Testimonial[]
}

const Story = ({
  project_owner_name,
  content,
  collected_amount,
  pledges_count,
  project_title,
  image,
  avatar,
  impact,
}: Testimonial) => {
  const { t } = useTranslation('home')
  const impacts: string[] = impact.reduce((acc: string[], val) => [...acc, val.impacts_id.title], [])

  const dataStory = [
    {
      title: t('collected_amount'),
      value: [
        <NumberFormat
          key="formatted_amount"
          value={collected_amount}
          displayType={'text'}
          decimalScale={0}
          thousandSeparator={' '}
          suffix={t('collected_amount_suffix')}
        />,
      ],
    },
    {
      title: t('contributors'),
      value: [
        <NumberFormat
          key="formatted_count"
          value={pledges_count}
          displayType={'text'}
          decimalScale={0}
          thousandSeparator={' '}
          suffix={t('contributors_suffix')}
        />,
      ],
    },
    {
      title: t('impact_category'),
      value: impacts,
    },
  ]

  return (
    <FocusStory
      imageUrl={directusAsset(image)}
      title={content}
      ownerFullName={project_owner_name}
      projectTitle={project_title}
      avatarUrl={directusAsset(avatar)}
      data={dataStory}
    />
  )
}

const TestimonialStories: FC<Props & BoxProps> = ({ testimonials }) => {
  return (
    <Container variant="full" position="relative">
      <Image
        src={s3ImageURL('home/testimonials-V4-home.jpg')}
        fill
        style={{ objectFit: 'cover' }}
        alt=""
        quality={100}
      />
      <Container variant="boxed" py="12">
        <Carousel
          draggable
          navBottom
          centeredControls
          data={testimonials}
          SlideComponent={Story}
          itemsPerPage={1}
          slidesToScroll={1}
          centerMode={false}
        />
      </Container>
    </Container>
  )
}

export default TestimonialStories
