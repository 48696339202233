import { Flex, Container, BoxProps, Heading, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Carousel } from '@miimosa/design-system/components'
import { toV2Link, usePlatform } from '@miimosa/design-system/lib/hooks'

import { ProjectCard } from '@components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderCards = (project: FrontSearchProjectType, i: number) => {
  const { imageFileName, slug, place, state, title, teasing, collect, labels, userFullName, avatarFileName } = project

  return (
    <Flex key={i} mx={{ base: 5, md: 0 }}>
      <ProjectCard
        imageFileName={imageFileName}
        slug={slug}
        title={title}
        teasing={teasing}
        goalAmount={collect?.goalAmount || 0}
        place={place}
        endsAt={collect?.endsAt}
        goalType={collect?.goalType || 'amount'}
        collectType={collect?.type || 'donation'}
        collectedAmount={collect?.collectedAmount || 0}
        progress={collect?.progress || 0}
        state={state}
        userFullName={userFullName}
        avatarFileName={avatarFileName}
        goalQuantity={collect?.goalQuantity || 0}
        collectedQuantity={collect?.collectedQuantity || 0}
        labels={labels}
        interestsRatio={collect?.interestsRatio}
      />
    </Flex>
  )
}

const NewProjects = ({ projects, ...props }: { projects: FrontSearchProjectType[] } & BoxProps) => {
  const { t } = useTranslation('home', { keyPrefix: 'new' })
  const { lang, platform } = usePlatform()
  const { t: tCommon } = useTranslation('common')
  const itemsPerPage = useBreakpointValue({ base: 1, md: 2, lg: 3, xl: 4, '3xl': 5 }, { fallback: 'xl' }) as number

  return (
    <Container bgColor="creamy" variant="full" px={{ base: 5 }} py={12} {...props}>
      <Flex width="100%" align="center" justify="space-between" mb="10">
        <Heading
          size="lg"
          px={{ base: 5 }}
          as="a"
          href={toV2Link('/projects', platform, lang)}
          textAlign={['center', 'left']}
        >
          {t('title')}
        </Heading>
        <NextLink href={platform == 'be' ? '/projects?type=donation' : '/projects?type=lending'} color="dark_green">
          {tCommon('see_all')}
        </NextLink>
      </Flex>
      <Carousel
        isDark
        draggable
        navBottom
        data={projects}
        SlideComponent={renderCards}
        itemsPerPage={itemsPerPage}
        slidesToScroll={1}
        centerMode={false}
      />
    </Container>
  )
}

export default NewProjects
