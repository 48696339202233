export function getBaseRoute(): string {
  return `${process.env.NEXT_PUBLIC_DIRECTUS_ENDPOINT}`
}

export function getPage(query: Record<string, string | string[] | undefined>) {
  const p = query.page
  if (!p) return 0
  return parseInt(Array.isArray(p) ? p[0] : p) || 0
}

export function calculateNumberOfPages(perPage: number, total: number) {
  return Math.ceil(total / perPage)
}
