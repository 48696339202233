import { AxiosResponse } from 'axios'

export type Operator =
  | '_eq'
  | '_neq'
  | '_lt'
  | '_lte'
  | '_gt'
  | '_gte'
  | '_in'
  | '_nin'
  | '_null'
  | '_nnull'
  | '_contains'
  | '_ncontains'
  | '_starts_with'
  | '_nstarts_with'
  | '_ends_with'
  | '_nends_with'
  | '_between'
  | '_nbetween'
  | '_empty'
  | '_nempty'
  | '_intersects'
  | '_nintersects'
  | '_intersects_bbox'
  | '_nintersects_bbox'

export type Filter = Record<string, Partial<Record<Operator, unknown> | string>>

export type Logic = Record<'_or' | '_and', Filter[]>

export interface Query {
  resource: string
  fields?: string[]
  sort?: string
  filter?: Filter | Logic
  page?: number
  limit?: number
  deep?: { translations: { _filter: Filter | Logic } }
  meta?: 'filter_count' | 'total_count'
}

export interface SingleResponse<T> {
  data: T
}

export interface ArrayResponse<T> extends SingleResponse<T[]> {
  meta?: {
    filter_count?: number
  }
}

export type Fetcher<R> = (q: Query) => Promise<AxiosResponse<R>>

export type DirectusLendingStatsResponse = {
  project_count: number
  average_interest_rate: number
  projects_success_rate: number
  total_collected_amount: number // the | string is to improve compatibility with back api
  total_repaid_amount: number
  lenders_count: number
  legal_pledges_rate: number
  average_collected_amount: number
  average_collect_duration: number
  average_repay_term: number
  average_pledge_amount: number
  late_term_rate: number
  default_term_rate: number
  intro: string
  riskDirectorWords: string
  risk_director_picture: Image
  businessNewsFirstText: string
  businessNewsSecondText: string
  legalIndicatorsText: string
  resultsIndicatorsText: string
  businessNewsDate: string
  fpf_performance_indicators: Image
  acpr_default_rates: Image
}

export type Testimonial = {
  id: number
  status: string
  sort: string
  user_created: string
  date_created: string
  user_updated: string
  date_updated: string
  position: number
  project_owner_name: string
  project_title: string
  content: string
  collected_amount: number
  collect_duration: number
  collect_type: string
  category: string
  pledges_count: number
  image: Image
  project_url: string
  avatar: Image
  impact: {
    impacts_id: {
      title: string
      slug: string
    }
  }[]
}

export type TestimonialInvest = {
  image: string
  content: string
  user_name: string
  user_bio: string
}

export type DirectusManifestResponse = {
  projectFunded: number
  moneyPower: number
  moneyForTransition: number
  keyElement: number
  feelings: number
  teamFervent: number
  teamHoursPhone: number
  teamFamilyFarmers: number
  teamWorkFarms: number
  teamBike: number
  teamEatLocal: number
}

export type PartnerWidget = {
  brand: string
  website_url: string
  image: Image
  logo: string
  translations: { description: string; languages_id: string }[]
}

export type Image = {
  filename_disk: string
}

export function directusAsset(image: Image) {
  return process.env.NEXT_PUBLIC_DIRECTUS_BUCKET + '/' + image?.filename_disk
}

export type Partnership = {
  name: string
  url: string
  image: Image
  hashtag: string
  desc: string
}

export type Partner = {
  image: Image
  name: string
  slug: string
  translations: { description: string; short_description: string; languages_id: string }[]
}

export type UseCase = {
  description: string
  photo: Image
}

export type FunnelExample = {
  image: Image
  project_name: string
  firstname: string
  collect_type: ('donation' | 'lending')[]
  position: number
  achievment: string
}
export type ManifestTestimony = {
  username: string
  link_to?: string
  image: Image
  translations: [{ title: string; description: string; link_name?: string }]
}

export type PlatformMetrics = {
  projects_count: number
  users_count: number
  collected_amount: number
}

export type CarassoCategory = {
  slug: string
  title: string
}

export type CarassoImpact = {
  slug: string
  title: string
  color: string
}

export type CarassoProjectCard = {
  project_title: string
  location: string
  slug: string
  miimosa_slug?: string | null
  description: string
  name: string
  photo_porteur: Image
  photo_projet: Image
  dotation_amount: number
  tagline: string
  siren: string
  project_description: string
  jury_conclusion: string
  jury_member: string
  choice_motivation: string
  project_owner_description: string
  funding_objective: string
  impact: { carasso_impacts_id: CarassoImpact }[]
  category: CarassoCategory[]
  region: string
  news: CarassoNews[]
}

export type CarassoNews = {
  title: string
  project: { project_title: string; slug: string }
  news_description: string
  date: string
}

export type VigneronProject = {
  owner: string
  age: string
  region: string
  slug: string
  awarded: boolean
  impact: string[]
}

export type InnocentKidsProject = {
  title: string
  location: string
  description: string
  image: Image
}
