import { Flex, Container, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { Discover } from '../../../'

const Commitments: FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation('home', { keyPrefix: 'commitments' })
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Container variant="full" py={12} {...props}>
      <Flex px={{ base: 5, md: 'inherit' }}>
        <Discover
          title={t('title')}
          description={t('description')}
          href="/manifest"
          link={t('link')}
          emphasis={t('emphasis')}
          isHorizontal={isMobile ? false : true}
          imageUrl={'images/home/bcorp.png'}
        />
      </Flex>
    </Container>
  )
}

export default Commitments
