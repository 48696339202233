import { Flex, Heading, Button, Image } from '@chakra-ui/react'
import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import NextLink from 'next/link'
import { useEffect, useRef, useState } from 'react'

const Hero = () => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(useGSAP)
  const containerRef = useRef<HTMLDivElement>(null)
  const primaryHeroImageRef = useRef<HTMLDivElement>(null)
  const secondaryHeroImageRef = useRef<HTMLDivElement>(null)

  const [imgHeight, setImgHeight] = useState<number | undefined>(undefined)

  // Needed to make animation works on window resize..
  useEffect(() => {
    setImgHeight(primaryHeroImageRef.current?.getBoundingClientRect().height)
    const calculateImgHeight = () => {
      setImgHeight(primaryHeroImageRef.current?.getBoundingClientRect().height)
    }
    window.addEventListener('resize', calculateImgHeight)
  }, [])

  useGSAP(
    () => {
      if (imgHeight && imgHeight > 0) {
        const tlForPrimarySetup = gsap
          .timeline()
          .to('#primaryText', { lineHeight: '6rem', opacity: 0 }, '-=1')
          .to('#primaryText2', { lineHeight: '6rem', opacity: 0, delay: 0.2 }, '<')
          .to('.primaryText', { display: 'none' }, '<')

        ScrollTrigger.create({
          trigger: '#primaryHeroImage',
          start: 'top 72px',
          end: `+=${imgHeight}`,
          pin: true,
          animation: tlForPrimarySetup,
          scrub: true,
          pinSpacing: false,
        })
      }

      if (secondaryHeroImageRef.current) {
        const tlForSecondarySetup = gsap
          .timeline()
          .to('#secondaryText', { lineHeight: '3.6rem', opacity: 1 })
          .to('.button', { opacity: 1 })
          .fromTo('.button', { y: '50' }, { y: '0' })

        ScrollTrigger.create({
          trigger: '#secondaryHeroImage',
          start: 'top center',
          end: `top 72px`,
          animation: tlForSecondarySetup,
          scrub: 1,
          pinSpacing: false,
        })
      }
    },
    { dependencies: [imgHeight], scope: containerRef, revertOnUpdate: true }
  )

  return (
    <Flex w="full" id="container" ref={containerRef} direction="column" justifyContent="center" alignItems="center">
      {/* Position fixed needed to have heading crossing second img */}
      <Flex direction="column" w="full" position="fixed" top={{ base: '40vh', md: '50vh' }} zIndex={2}>
        <Heading
          as="h1"
          size="2xl"
          whiteSpace="pre-wrap"
          textAlign="center"
          fontWeight="400"
          color="white"
          id="primaryText"
          className="primaryText"
        >
          {"L'agriculture et l'alimentation"}
        </Heading>
        <Heading
          size="2xl"
          whiteSpace="pre-wrap"
          textAlign="center"
          fontWeight="400"
          color="white"
          id="primaryText2"
          className="primaryText"
        >
          {"de demain se financent aujourd'hui."}
        </Heading>
      </Flex>
      <Flex
        w="full"
        h={'calc(100vh - 72px)'}
        position="relative"
        justifyContent="center"
        alignItems="center"
        ref={primaryHeroImageRef}
        id="primaryHeroImage"
        direction="column"
        bgImage={'/images/home/hero_1.jpg'}
        backgroundSize="cover"
        filter="brightness(0.8)"
        bgPos={{ base: 'right', lg: 'initial' }}
      />
      <Flex
        w="full"
        h={'calc(100vh - 72px)'}
        position="relative"
        justifyContent="center"
        alignItems="center"
        ref={secondaryHeroImageRef}
        id="secondaryHeroImage"
        direction="column"
        rowGap={10}
      >
        <Image
          src={'/images/home/hero_2.jpg'}
          alt={''}
          w="full"
          h="full"
          objectFit="cover"
          position="absolute"
          // fill
          // style={{
          //   objectFit: 'cover',
          // }}
          // sizes="100vh"
          // priority
        />
        <Heading
          as="h2"
          color="white"
          size="2xl"
          whiteSpace="pre-wrap"
          textAlign="center"
          fontWeight="400"
          position="relative"
          id="secondaryText"
          opacity={0}
          lineHeight={'6rem'}
        >
          {'Investir et agir\n concrètement pour demain.'}
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} rowGap={4} columnGap={4}>
          <NextLink href={'/projects?type=donation'}>
            <Button variant="secondaryW" size="md" w="250px" className="button" opacity={0}>
              Don avec contrepartie
            </Button>
          </NextLink>
          <NextLink href={'/projects?type=lending'}>
            <Button variant="secondaryW" size="md" w="250px" className="button" opacity={0}>
              Investissement à impact
            </Button>
          </NextLink>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Hero
