import axios from 'axios'
import qs from 'qs'

import { Query } from './types'

const client = axios.create({
  baseURL: '/nextapi',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'comma' })
  },
})

// proxied calls the next api and is safe to use from the client side
export function proxied<T>({ resource, filter, ...q }: Query) {
  const params: Record<string, unknown> = {
    resource,
    sort: 'position',
    limit: 20,
    ...q,
  }
  if (filter) {
    params.filter = JSON.stringify(filter)
  }
  return client.get<T>('cms', { params })
}
