import { Container, Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { getImpactCategories, s3ImageURL } from '@miimosa/common'
import { Carousel } from '@miimosa/design-system/components'

import { ImageWithTitle, HeadingBlock } from '../../../'

const renderImpact = (impact: Record<string, string>, i: number) => {
  const { title, imageUrl, href } = impact
  return (
    <Box mx={{ base: 2, md: 2 }} key={i}>
      <ImageWithTitle title={title} isLight imageUrl={imageUrl} href={href} />
    </Box>
  )
}

const Impacts: FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation(['common', 'home'])
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const impactCategoriesData = getImpactCategories(t).map((d) => ({
    ...d,
    href: `/projects?impact=${d.slug}`,
    imageUrl: s3ImageURL(`impact/${d.imageUrl}`),
  }))
  const itemsPerPage = useBreakpointValue({ base: 1, md: 3, lg: 4, '2xl': 5 }, { fallback: 'lg' }) as number

  return (
    <Container variant="full" py={12} {...props}>
      <Container variant="boxed" py={0}>
        <HeadingBlock title={t('impacts.title', { ns: 'common' })} mb={8} />
      </Container>
      <Carousel
        isDark
        draggable
        navBottom
        centerMode
        centerPadding={isMobile ? '40px' : '60px'}
        boxedControls
        data={impactCategoriesData}
        SlideComponent={renderImpact}
        itemsPerPage={itemsPerPage}
        slidesToScroll={1}
      />
    </Container>
  )
}

export default Impacts
